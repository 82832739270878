
import { defineComponent, computed } from 'vue';

import { Information } from '@/constants/Information';
import { useScanStore } from '@/stores/scan';
import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  components: {},
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
      default: true,
    },
    previewReport: {
      type: Boolean,
      required: false,
      value: false,
    },
    imageGuide: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);
    const labelsStore = useLabelsStore();
    const vdpLabel = computed(() => labelsStore.getLabels?.vdp);

    return { getScan, vdpLabel, Information };
  },
});
