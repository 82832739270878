import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  class: "max-[596px]:grid px-4 py-1 border-2 border-teal xl:text-[1vw] block xl:w-auto",
  "data-cy": "tidal-volume"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_BaseSubCard = _resolveComponent("BaseSubCard")!

  return (_openBlock(), _createBlock(_component_BaseSubCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BaseSubHeader, {
          title: _ctx.vdpLabel,
          markdownTitle: true,
          showInformationModal: _ctx.showModal,
          informationModalText: _ctx.Information.VENTILATION_GRAPH_HISTOGRAM,
          imageGuide: _ctx.imageGuide
        }, null, 8, ["title", "showInformationModal", "informationModalText", "imageGuide"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getScan?.histogram.vdp) + " % ", 1)
      ])
    ]),
    _: 1
  }))
}