
import ScanMetrics from '@/components/ScanMetrics.vue';
import VentHeterog from '@/components/VentHeterog.vue';
import { defineComponent, onMounted, computed, watch, ref } from 'vue';
import VentilationDistribution from '../components/reports/VentilationDistributionChart.vue';
import Main4DView from '../components/reportSummary/Main4DView.vue';
import VentDistributionPercent from '@/components/VentDistributionPercent.vue';
import { Information } from '@/constants/Information';
import { useScanStore } from '@/stores/scan';
import { useLabelsStore } from '@/stores/labels';
import { useNotification } from '@/composables/notification';
import { useInformationStore } from '@/stores/information';
import { useContactStore } from '@/stores/contact';
import { marked } from 'marked';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    ScanMetrics,
    VentHeterog,
    VentilationDistribution,
    Main4DView,
    VentDistributionPercent,
  },
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
      value: true,
      default: true,
    },
    previewReport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);
    const preambleContent = ref('');
    const labelsStore = useLabelsStore();
    const contactStore = useContactStore();
    const disclaimerKey = 'preamble.md';
    const disclaimer = computed(() => contactStore.getDisclaimer(disclaimerKey));
    const getLabels = computed(() => labelsStore.getLabels);
    const vdpLabel = computed(() => labelsStore.getLabels?.vdp);
    const router = useRouter();
    const informationStore = useInformationStore();
    const getReportGuideImages = computed(() => informationStore.getReportGuideImages);

    const notification = useNotification();

    const getVDPClass = computed(() =>
      getScan?.value?.product === 'DAW'
        ? 'max-[596px]:grid px-4 py-1 border-2 border-teal text-heading-5 4xl:text-heading-3 block xl:w-41 3xl:w-77 4xl:w-420'
        : 'max-[596px]:grid px-4 py-1 border-2 border-teal max-[596px]:text-heading-5 xl:text-body-1 3xl:text-heading-6 4xl:text-heading-3 block xl:w-200 3xl:w-480 4xl:w-700',
    );

    const getVDPCardClass = computed(() =>
      getScan?.value?.product === 'DAW'
        ? 'xl:mt-3 xl:inline-block xl:ml-[0.875rem] 3xl:ml-4 4xl:ml-8'
        : 'inline-block 3xl:ml-0',
    );

    onMounted(async () => {
      if (!getLabels.value && getScan.value) {
        const resp = await labelsStore.fetchLabels(getScan.value.resultId);

        if (!resp.success) {
          notification.showError(resp.errorMsg);
        }
      }

      if (getScan.value) {
        if (props.previewReport && !disclaimer.value) {
          const resp = await contactStore.fetchDisclaimer(disclaimerKey, getScan.value.resultId);

          if (!resp.success) {
            router.push('/error/500');
          }
        }
      }
    });

    return {
      getScan,
      Information,
      vdpLabel,
      getReportGuideImages,
      getVDPClass,
      getVDPCardClass,
      preambleContent,
      marked,
      disclaimer,
    };
  },
});
