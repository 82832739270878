import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col lg:flex-row" }
const _hoisted_2 = { class: "p-2 w-full lg:w-1/2" }
const _hoisted_3 = { class: "grid gap-1 grid-cols-3 sm:grid-cols-6 max-[596px]:grid-cols-6" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["data-cy"]
const _hoisted_6 = {
  key: 0,
  class: "p-2 w-full lg:w-1/2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_BaseImg = _resolveComponent("BaseImg")!
  const _component_ColorBar = _resolveComponent("ColorBar")!
  const _component_SliceComponent = _resolveComponent("SliceComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSubHeader, {
        "data-cy": "left-header-plane",
        title: _ctx.sliceHeader,
        onSubHeader: _ctx.onSubHeader
      }, null, 8, ["title", "onSubHeader"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slices.length, (value, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "relative cursor-pointer",
            onClick: ($event: any) => (_ctx.sliceOnClick(_ctx.slices[index]?.url))
          }, [
            _createVNode(_component_BaseImg, {
              imageUrl: _ctx.slices[index]?.url,
              spinnerLoading: true
            }, null, 8, ["imageUrl"]),
            _createElementVNode("div", {
              class: "text-overline absolute top-[4px] left-[5px] text-white",
              "data-cy": `${index}-slice`
            }, _toDisplayString(_ctx.slices[index].spacingIndex % 1 == 0 ? _ctx.slices[index].spacingIndex : _ctx.slices[index].spacingIndex?.toFixed(1)), 9, _hoisted_5)
          ], 8, _hoisted_4))
        }), 128))
      ]),
      _createVNode(_component_ColorBar, { class: "mt-2 mx-7" })
    ]),
    (_ctx.showSlicedZoom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_BaseSubHeader, {
            "data-cy": "right-header-zoom",
            title: _ctx.$t('sliceDisplay.zoom'),
            onSubHeader: _ctx.onSubHeader
          }, null, 8, ["title", "onSubHeader"]),
          _createVNode(_component_SliceComponent, {
            imageUrl: _ctx.zoomedImage,
            "data-cy": "zoomed-image"
          }, null, 8, ["imageUrl"])
        ]))
      : _createCommentVNode("", true)
  ]))
}