
import { defineComponent, computed } from 'vue';
import { useScanStore } from '@/stores/scan';

import { ReportTypes } from '@/constants/ReportTypes';
import { Information } from '@/constants/Information';

import { useLabelsStore } from '@/stores/labels';

import { marked } from 'marked';

export default defineComponent({
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);

    const labelsStore = useLabelsStore();
    const getLabels = computed(() => labelsStore.getLabels);
    const tvLabel = computed(() => getLabels.value?.tv || '');
    const evLabel = computed(() => getLabels.value?.ev || '');
    const ivLabel = computed(() => getLabels.value?.iv || '');
    const vcLabel = computed(() => getLabels.value?.vc || '');
    const lvLabel = computed(() => getLabels.value?.lv || '');

    const reportType = computed(() => (getScan.value?.product === ReportTypes.DAW ? tvLabel.value : lvLabel.value));
    return { getScan, ReportTypes, Information, tvLabel, evLabel, ivLabel, vcLabel, lvLabel, marked, reportType };
  },
});
