
import DownIcon from '@/assets/images/icons/down-turquoise.svg';
import UpIcon from '@/assets/images/icons/up-blue.svg';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ReportTypes } from '@/../src/constants/ReportTypes';
import { useScanStore } from '@/stores/scan';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const scanStore = useScanStore();

    let menu = computed(() => {
      let navigationBar = [
        {
          title: t('navigationBar.reportResults'),
          child: [
            {
              title: t('navigationBar.reportSummary'),
              href: '/result-summary',
            },
            {
              title: t('navigationBar.axialImages'),
              href: '/axial-images',
            },
            {
              title: t('navigationBar.coronalImages'),
              href: '/coronal-images',
            },
            {
              title: t('navigationBar.ventilation4DVideo'),
              href: '/ventilation-video',
              class: 'ventilation4DVideo',
            },
          ],
        },
        {
          title: t('navigationBar.contact4DMedical'),
          href: '/contact4d-medical',
        },
      ];

      if (getScan.value) {
        if (getScan.value.product === ReportTypes.EWD) {
          navigationBar[0].child?.pop();
        }
      }

      return navigationBar;
    });

    const downIcon = DownIcon;
    const upIcon = UpIcon;
    const getScan = computed(() => scanStore.getScan);

    return { menu, downIcon, upIcon };
  },
});
