
import { ref, defineComponent, computed, onMounted, onBeforeMount } from 'vue';
import { Information } from '@/constants/Information';
import SliceDisplay from '@/components/SliceDisplay.vue';
import { useSliceStore } from '@/stores/slice';
import { useScanStore } from '@/stores/scan';
import { Views } from '@/constants/Views';
import { SliceImageTypes } from '@/constants/SliceImageTypes';
import { getImageBlob } from '@/utility/helpers';

export default defineComponent({
  components: {
    SliceDisplay,
  },
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSlicedZoom: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const sliceStore = useSliceStore();

    const axialSlices = computed(() => {
      let slices = sliceStore.filterSliceImages(Views.AXIAL, false, null).map((s) => {
        const staticFile = s.files.find((f) => f.type === SliceImageTypes.STATIC);
        return {
          url: staticFile?.url,
          spacingIndex: staticFile?.spacingIndex,
        };
      });

      return slices;
    });

    const dialogState = ref(false);

    const changeDialogState = () => {
      dialogState.value = true;
    };

    const scanStore = useScanStore();
    const spacing = computed(() => scanStore.getScan?.axialSpacing);
    const axialUrl = ref<string[]>([]);

    onBeforeMount(() => {
      if (axialSlices.value.filter((s) => !s.url?.startsWith('blob')).length > 0) {
        getImageBlob('Axial');
      }
    });

    return {
      dialogState,
      changeDialogState,
      axialSlices,
      Information,
      spacing,
      axialUrl,
      // getAxialImages,
    };
  },
});
