import { defineStore } from 'pinia';
import axios from 'axios';
import { Information } from '@/constants/Information';
import { InformationTooltip } from '@/types/InformationTooltip';
import { handleEndpointErrors, handleEndpointSuccess } from '@/utility/helpers';

import { ReportGuideImages } from '@/types/ReportGuideImages';

interface State {
  informationTooltip: InformationTooltip[];
  reportGuideImages: ReportGuideImages;
}

export const useInformationStore = defineStore('information', {
  // convert to a function
  state: (): State => ({
    informationTooltip: [],
    reportGuideImages: {
      visualizationExample: '',
      histogramExample: '',
      coronalViewExample: '',
      ventilationAnimationExample: '',
      logo: '',
      ctvSummaryExample: '',
    },
  }),
  getters: {
    // firstName getter removed, no longer needed
    getInformationTooltip: (state) => state.informationTooltip,
    getReportGuideImages: (state) => state.reportGuideImages,
  },
  actions: {
    // no context as first argument, use `this` instead
    async getInformationTooltipRequest(header: any, scanId: any) {
      try {
        const resp = await axios.get(`/api/viewer/result/${scanId}/content/guide/${header}`);

        this.setInformationTooltip({
          key: header,
          data: resp.data,
        });

        return handleEndpointSuccess({ success: true });
      } catch (e: unknown) {
        return handleEndpointErrors(e);
      }
    },

    async getProductLogoImageRequest(scanId: any, reportType: string) {
      const dawLogoFile = 'daw-logo-html.svg';
      const ewdLogoFile = 'ewd-logo-html.svg';

      let image = null;

      let imageToFetch = dawLogoFile;
      if (reportType === 'EWD') {
        imageToFetch = ewdLogoFile;
      }
      try {
        const resp = await axios.get(`/api/viewer/result/${scanId}/content/images/${imageToFetch}`, {
          responseType: 'blob',
        });
        const blob = new Blob([resp?.data], { type: 'image/svg+xml' });
        image = URL.createObjectURL(blob);
        this.reportGuideImages['logo'] = image;
        return handleEndpointSuccess({ success: true });
      } catch (e: unknown) {
        return handleEndpointErrors(e);
      }
    },

    async getReportGuideImagesRequest(scanId: any, reportType: string) {
      const dawContentFiles: string[] = [
        'visualization-example-v2.png',
        'histogram-examples-v2.png',
        'coronal-view-example.png',
        'ventilation-animation-example.png',
      ];
      const ewdContentFiles: string[] = [
        'visualization-example-v2.png',
        'histogram-examples-v2.png',
        'coronal-view-example.png',
        'ventilation-animation-example.png',
        'ctv-summary-page-example.png',
      ];
      let image = null;

      let contentToFetch = dawContentFiles;
      if (reportType === 'EWD') {
        contentToFetch = ewdContentFiles;
      }

      for (const [index, element] of contentToFetch.entries()) {
        try {
          const resp = await axios.get(`/api/viewer/result/${scanId}/content/images/${element}`, {
            responseType: 'blob',
          });
          const blob = new Blob([resp?.data]);
          image = URL.createObjectURL(blob);
          const keys = Object.keys(this.reportGuideImages)[index];
          this.reportGuideImages[keys as keyof ReportGuideImages] = image;
        } catch (e: unknown) {
          return handleEndpointErrors(e);
        }
      }
      return handleEndpointSuccess({ success: true });
    },

    setInformationTooltip(payload: any) {
      this.informationTooltip.push({ key: payload.key, data: payload.data });
    },
  },
});
