
import { ReportTypes } from '@/../src/constants/ReportTypes';

import { computed, defineComponent, onMounted } from 'vue';
import { Information } from '@/constants/Information';
import { useInformationStore } from '@/stores/information';
import InformationModal from '@/components/InformationModal.vue';
import { useScanStore } from '@/stores/scan';

export default defineComponent({
  components: {
    InformationModal,
  },
  props: {
    reportType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const informationStore = useInformationStore();
    const scanStore = useScanStore();

    const getReportGuideImages = computed(() =>
      informationStore.getReportGuideImages ? informationStore.getReportGuideImages : {},
    );

    const reportTypeTitle = computed(() =>
      props.reportType === ReportTypes.DAW ? 'reportPreview.readReport' : 'reportPreview.readReportEWD',
    );

    const getScan = computed(() => scanStore.getScan);

    onMounted(async () => {
      const resp = await informationStore.getReportGuideImagesRequest(getScan.value?.resultId, getScan.value!.product);
    });

    return { Information, ReportTypes, getReportGuideImages, reportTypeTitle };
  },
});
