
import { defineComponent, computed, ref, onMounted, onBeforeMount } from 'vue';
import { Information } from '@/constants/Information';
import SliceDisplay from '@/components/SliceDisplay.vue';
import { useSliceStore } from '@/stores/slice';
import { useScanStore } from '@/stores/scan';

import { Views } from '@/constants/Views';
import { SliceImageTypes } from '@/constants/SliceImageTypes';
import { useNotification } from '@/composables/notification';
import { getImageBlob } from '@/utility/helpers';

export default defineComponent({
  components: {
    SliceDisplay,
  },
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
      default: true,
    },

    showSlicedZoom: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const sliceStore = useSliceStore();

    const coronalSlices = computed(() => {
      const slices = sliceStore.filterSliceImages(Views.CORONAL, false, null).map((s) => {
        const staticFile = s.files.find((f) => f.type === SliceImageTypes.STATIC);

        return {
          url: staticFile?.url,
          spacingIndex: staticFile?.spacingIndex,
        };
      });

      return slices;
    });

    const scanStore = useScanStore();
    const spacing = computed(() => scanStore.getScan?.coronalSpacing);
    const coronalUrl = ref<string[]>([]);

    onBeforeMount(() => {
      if (coronalSlices.value.filter((s) => !s.url?.startsWith('blob')).length > 0) {
        getImageBlob('Coronal');
      }
    });

    return { coronalSlices, Information, spacing, coronalUrl };
  },
});
