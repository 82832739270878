import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "max-[596px]:grid text-center px-4 py-1 border-2 border-teal xl:text-[1vw] block",
  "data-cy": "tidal-volume"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-row justify-between text-center 3xl:flex-row"
}
const _hoisted_3 = { class: "max-[596px]:pr-1 basis-1/3 xl:mr-4 3xl:mr-2 4xl:mr-10" }
const _hoisted_4 = {
  class: "max-[596px]:text-heading-5 border-2 border-teal xl:text-[1vw]",
  "data-cy": "inspiration-volume"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "basis-1/3 xl:mr-4 3xl:mr-2 4xl:mr-10" }
const _hoisted_7 = {
  class: "max-[596px]:text-heading-5 border-2 border-teal xl:text-[1vw]",
  "data-cy": "expiration-volume"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "basis-1/3 max-[596px]:pl-1" }
const _hoisted_10 = {
  class: "max-[596px]:text-heading-5 border-2 border-teal xl:text-[1vw]",
  "data-cy": "change-in-volume"
}
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_BaseSubCard = _resolveComponent("BaseSubCard")!

  return (_openBlock(), _createBlock(_component_BaseSubCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseSubHeader, {
        title: _ctx.reportType,
        showInformationModal: _ctx.showInformationModal,
        informationModalText: _ctx.Information.TIDAL_VOLUME,
        markdown: true
      }, null, 8, ["title", "showInformationModal", "informationModalText"]),
      (_ctx.getScan?.product === _ctx.ReportTypes.DAW)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(`${_ctx.getScan?.scanMetrics.tidalVolumeQuant.toFixed(2)} L`), 1))
        : _createCommentVNode("", true),
      (_ctx.getScan?.product === _ctx.ReportTypes.EWD)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(`${_ctx.getScan?.scanMetrics.inspirationVolume?.toFixed(1)} L`), 1),
              _createElementVNode("div", {
                class: "prose text-overline xl:text-[1vw]",
                innerHTML: _ctx.marked.parse(_ctx.ivLabel)
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(`${_ctx.getScan?.scanMetrics.expirationVolume?.toFixed(1)} L`), 1),
              _createElementVNode("div", {
                class: "prose text-overline xl:text-[1vw]",
                innerHTML: _ctx.marked.parse(_ctx.evLabel)
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(`${_ctx.getScan?.scanMetrics.volumeChange?.toFixed(1)} L`), 1),
              _createElementVNode("div", {
                class: "prose text-overline xl:text-[1vw]",
                innerHTML: _ctx.marked.parse(_ctx.vcLabel)
              }, null, 8, _hoisted_11)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}