import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48f5a588"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-[596px]:w-full max-w-full space-y-1" }
const _hoisted_2 = { class: "flex flex-col space-x-1 md:flex-row max-[596px]:inline-flex max-[596px]:flex-row" }
const _hoisted_3 = { class: "flex justify-center max-[596px]:!w-full w-[64%] ml-[3%] w-l mfulb-1 md:mb-0" }
const _hoisted_4 = {
  key: 0,
  class: "axial max-[596px]:!block max-[596px]:basis-5/12 max-[596px]:!w-1/3"
}
const _hoisted_5 = { class: "w-[85%]" }
const _hoisted_6 = { class: "w-[85%]" }
const _hoisted_7 = { class: "w-[85%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_Vent4DZoomImg = _resolveComponent("Vent4DZoomImg")!
  const _component_BaseImg = _resolveComponent("BaseImg")!
  const _component_ColorBar = _resolveComponent("ColorBar")!
  const _component_BaseSubCard = _resolveComponent("BaseSubCard")!

  return (_openBlock(), _createBlock(_component_BaseSubCard, {
    class: "!pb-[1vh] xl:pt-2",
    "data-cy": "Main4DView"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseSubHeader, {
        title: _ctx.$t('reportSummaryPage.ventDynmView'),
        showInformationModal: _ctx.showInformationModal,
        showTitle: _ctx.showTitle,
        informationModalText: _ctx.Information.REGIONAL_VENTILATION_VISUALIZATION
      }, null, 8, ["title", "showInformationModal", "showTitle", "informationModalText"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Vent4DZoomImg, {
              imageUrl: _ctx.coronalImageUrl,
              showGuides: true,
              guides: _ctx.axialGuides
            }, null, 8, ["imageUrl", "guides"])
          ]),
          (_ctx.axialNamedImagesUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.upper')), 1),
                  _createVNode(_component_BaseImg, {
                    imageUrl: _ctx.axialNamedImagesUrl[0],
                    spinnerLoading: true
                  }, null, 8, ["imageUrl"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.middle')), 1),
                  _createVNode(_component_BaseImg, {
                    imageUrl: _ctx.axialNamedImagesUrl[1],
                    spinnerLoading: true
                  }, null, 8, ["imageUrl"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.lower')), 1),
                  _createVNode(_component_BaseImg, {
                    imageUrl: _ctx.axialNamedImagesUrl[2],
                    class: "max-[596px]:w-[96%]",
                    spinnerLoading: true
                  }, null, 8, ["imageUrl"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_ColorBar, { class: "4xl:mx-[7.5rem] w-[52%] mx-14 max-[596px]:mx-12" })
        ])
      ])
    ]),
    _: 1
  }))
}