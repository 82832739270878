<template>
  <div class="container">
    <BaseHeader :title="$t('contact.header')" class="mb-4 max-[596px]:mb-0" />
    <BaseCard class="max-[596px]:p-2 max-[596px]:w-[70vh]">
      <div class="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <ContactLabel data-cy="contact-label" />
        <RegLabel data-cy="reg-label" />

        <div class="col-span-1 lg:col-span-2 ml-auto">
          <p class="block">Prepared By ReportViewer {{ appVersion }}</p>
          <p class="inline-block" data-cy="job-id">{{ $t('contact.jobID') }} {{ scan.jobId }}</p>
          <p class="inline-block ml-2" data-cy="issueNumber">{{ $t('contact.issueNumber') }} {{ scan.issueNumber }}</p>
        </div>
        <div class="col-span-1 lg:col-span-2" data-cy="disclaimer">
          <div
            v-if="disclaimer"
            class="prose marked-font inline max-[596px]:text-[.65rem]"
            v-html="marked.parse(disclaimer)"
          ></div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import ContactLabel from '../components/shareReport/ContactLabel.vue';
import RegLabel from '../components/shareReport/RegLabel.vue';
import { marked } from 'marked';
import { computed, onMounted, defineComponent } from 'vue';
import { useContactStore } from '@/stores/contact';
import { useScanStore } from '@/stores/scan';
import { useNotification } from '@/composables/notification';

export default defineComponent({
  name: 'Contact4DMedical',
  components: {
    ContactLabel,
    RegLabel,
  },
  props: {
    previewReport: {
      type: Boolean,
      value: false,
    },
  },
  setup() {
    const contactStore = useContactStore();
    const scanStore = useScanStore();
    const notification = useNotification();

    const scan = computed(() => scanStore.getScan);
    const contact = computed(() => contactStore.getContact);
    const disclaimerKey = 'general-disclaimer.md';
    const disclaimer = computed(() => contactStore.getDisclaimer(disclaimerKey));
    const appVersion = computed(() => window.VUE_APP_VERSION || '');

    onMounted(async () => {
      if (!contact.value) {
        const resp = await contactStore.fetchContact(scan.value.resultId);

        if (!resp.success) {
          notification.showError(resp.errorMsg + '');
          return;
        }
      }
      if (!disclaimer.value) {
        const resp = await contactStore.fetchDisclaimer(disclaimerKey, scan.value.resultId);

        if (!resp.success) {
          notification.showError(resp.errorMsg + '');
          return;
        }
      }
    });

    return {
      disclaimer,
      marked,
      scan,
      appVersion,
    };
  },
});
</script>
