import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationModal = _resolveComponent("InformationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "text-body-1 3xl:text-heading-5 4xl:text-heading-2",
      innerHTML: _ctx.titleText
    }, null, 8, _hoisted_2),
    (_ctx.showInformationModal)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.informationIcon,
          class: "w-6 3xl:w-10 4xl:w-16",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeDialogState())),
          "data-cy": 'information-' + _ctx.title
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createVNode(_component_InformationModal, {
      modelValue: _ctx.dialogState,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogState) = $event)),
      title: _ctx.titleText,
      markdownTitle: _ctx.informationModalText,
      imageGuide: _ctx.imageGuide,
      dialogState: _ctx.dialogState
    }, null, 8, ["modelValue", "title", "markdownTitle", "imageGuide", "dialogState"])
  ]))
}