
import { defineComponent, computed } from 'vue';
import { useScanStore } from '@/stores/scan';
import { Information } from '@/constants/Information';
import { useInformationStore } from '@/stores/information';

import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const scanStore = useScanStore();
    const ventilationHeterogeneity = computed(() => scanStore.getScan?.scanMetrics.ventilationHeterogeneity);
    const information = useInformationStore();
    const getInformation = computed(() => information.getReportGuideImages);

    const labelsStore = useLabelsStore();
    const vhLabel = computed(() => labelsStore.getLabels?.vh);

    return { ventilationHeterogeneity, Information, vhLabel, getInformation };
  },
});
