
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const route = useRoute();
    const message = ref('');
    const { t } = useI18n();

    switch (route.params.type) {
      case '404':
        message.value = t('errors.404');
        break;
      case '401':
        message.value = t('errors.401');
        break;
      case '403':
        message.value = t('errors.403');
        break;
      case '400':
        message.value = t('errors.400');
        break;
      case '502':
        message.value = t('errors.502');
        break;
      case '500':
      default:
        message.value = t('errors.500');
        break;
    }

    return { message };
  },
});
