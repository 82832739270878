import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid gap-4 gird-cols-3" }
const _hoisted_2 = { class: "col-span-3" }
const _hoisted_3 = { class: "col-span-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "grid grid-cols-23 gap-2" }
const _hoisted_6 = { class: "col-span-6 col-start-6 4xl:col-start-8" }
const _hoisted_7 = { class: "col-span-6" }
const _hoisted_8 = { class: "my-5 3xl:m-9 xl:mr-12" }
const _hoisted_9 = { class: "grid grid-flow-col grid-rows-2 gap-y-3 gap-x-5" }
const _hoisted_10 = { class: "w-5/6" }
const _hoisted_11 = { class: "mb-2 4xl:mb-4" }
const _hoisted_12 = { class: "w-5/6" }
const _hoisted_13 = { class: "mb-2 4xl:mb-4" }
const _hoisted_14 = { class: "flex items-end justify-center" }
const _hoisted_15 = { class: "flex items-center justify-center" }
const _hoisted_16 = {
  key: 0,
  class: "flex items-center justify-center row-span-2"
}
const _hoisted_17 = { class: "flex justify-end" }
const _hoisted_18 = { class: "w-5/6" }
const _hoisted_19 = { class: "mb-2 4xl:mb-4" }
const _hoisted_20 = { class: "flex justify-end" }
const _hoisted_21 = { class: "w-5/6" }
const _hoisted_22 = { class: "mb-2 4xl:mb-4" }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = {
  class: "grid grid-cols-3 grid-flow-row gap-4",
  id: "container"
}
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "mx-auto my-5 3xl:m-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_Vent4DZoomImg = _resolveComponent("Vent4DZoomImg")!
  const _component_BaseImg = _resolveComponent("BaseImg")!
  const _component_ColorBar = _resolveComponent("ColorBar")!
  const _component_BaseDropDown = _resolveComponent("BaseDropDown")!
  const _component_BaseSlider = _resolveComponent("BaseSlider")!
  const _component_BaseIconButton = _resolveComponent("BaseIconButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseHeader, {
        markdownTitle: _ctx.Information.VIDEO_FRAMES,
        title: _ctx.$t('ventilationAnimation.header'),
        showIcon: _ctx.showInformationModal
      }, null, 8, ["markdownTitle", "title", "showIcon"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseCard, null, {
        default: _withCtx(() => [
          _createVNode(_component_BaseSubHeader, {
            title: _ctx.previewReport ? _ctx.$t('ventVideo.desc') : _ctx.$t('ventilationAnimation.subHeader'),
            onSubHeader: _ctx.showInformationModal
          }, null, 8, ["title", "onSubHeader"]),
          (!_ctx.previewReport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (!_ctx.leftViewImage?.endsWith('.png'))
                      ? (_openBlock(), _createBlock(_component_Vent4DZoomImg, {
                          key: 0,
                          imageUrl: _ctx.leftViewImage,
                          showGuides: false,
                          "data-cy": "imageLeft"
                        }, null, 8, ["imageUrl"]))
                      : (_openBlock(), _createBlock(_component_BaseImg, {
                          key: 1,
                          imageUrl: _ctx.loadingBarImg,
                          spinnerLoading: true
                        }, null, 8, ["imageUrl"]))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    (!_ctx.rightViewImage?.endsWith('.png'))
                      ? (_openBlock(), _createBlock(_component_Vent4DZoomImg, {
                          key: 0,
                          imageUrl: _ctx.rightViewImage,
                          showGuides: false,
                          "data-cy": "imageRight"
                        }, null, 8, ["imageUrl"]))
                      : (_openBlock(), _createBlock(_component_BaseImg, {
                          key: 1,
                          imageUrl: _ctx.loadingBarImg,
                          spinnerLoading: true
                        }, null, 8, ["imageUrl"]))
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_ColorBar, { class: "w-[35%] m-auto" })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('ventVideo.selectView')), 1),
                    _createVNode(_component_BaseDropDown, {
                      options: _ctx.viewsArray,
                      modelValue: _ctx.leftViewValue,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.leftViewValue) = $event)),
                      onChange: _ctx.onLeftViewChange
                    }, null, 8, ["options", "modelValue", "onChange"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('ventVideo.selectSlice')), 1),
                    _createVNode(_component_BaseDropDown, {
                      options: _ctx.isCoronalLeft ? _ctx.coronalSlicesArray : _ctx.axialSlicesArray,
                      modelValue: _ctx.leftSliceValue,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.leftSliceValue) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_BaseSlider, {
                      modelValue: _ctx.phasePoint,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phasePoint) = $event)),
                      min: _ctx.minPhasePoint,
                      max: _ctx.maxPhasePoint
                    }, null, 8, ["modelValue", "min", "max"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_BaseIconButton, {
                      class: "w-6 rotate-180 3xl:w-10 4xl:w-20",
                      icon: "play",
                      onOnClick: _ctx.onLeftPlayerClick,
                      "data-cy": "left-arrow-player"
                    }, null, 8, ["onOnClick"]),
                    _createVNode(_component_BaseIconButton, {
                      class: "w-12 mx-5 3xl:w-16 4xl:w-32 4xl:mx-9",
                      icon: _ctx.isPlaying ? 'pause' : 'play',
                      onOnClick: _ctx.onPlayerClick,
                      "data-cy": "play-pause-player"
                    }, null, 8, ["icon", "onOnClick"]),
                    _createVNode(_component_BaseIconButton, {
                      class: "w-6 3xl:w-10 4xl:w-20",
                      icon: "play",
                      onOnClick: _ctx.onRightPlayerClick,
                      "data-cy": "right-arrow-player"
                    }, null, 8, ["onOnClick"])
                  ]),
                  (_ctx.maxPhasePoint === 7)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createVNode(_component_BaseImg, {
                          style: {"aspect-ratio":"auto"},
                          class: "h-full w-[17vh]",
                          imageUrl: require(`@/assets/images/phasePoints/graph.png`)
                        }, null, 8, ["imageUrl"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('ventVideo.selectView')), 1),
                      _createVNode(_component_BaseDropDown, {
                        options: _ctx.viewsArray,
                        modelValue: _ctx.rightViewValue,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.rightViewValue) = $event)),
                        onChange: _ctx.onRightViewChange
                      }, null, 8, ["options", "modelValue", "onChange"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('ventVideo.selectSlice')), 1),
                      _createVNode(_component_BaseDropDown, {
                        options: _ctx.isCoronalRight ? _ctx.coronalSlicesArray : _ctx.axialSlicesArray,
                        modelValue: _ctx.rightSliceValue,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.rightSliceValue) = $event))
                      }, null, 8, ["options", "modelValue"])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  (_ctx.coronalAnimatedSlices[1]?.files.length === 8)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createVNode(_component_BaseImg, {
                          style: {"aspect-ratio":"auto"},
                          class: "h-full",
                          imageUrl: require(`@/assets/images/phasePoints/graph.png`),
                          "data-cy": "phase-point-image"
                        }, null, 8, ["imageUrl"])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coronalVideoUrl, (result, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createVNode(_component_Vent4DZoomImg, {
                        imageUrl: result,
                        showGuides: false,
                        "data-cy": `coronal-${index}`,
                        previewReport: true
                      }, null, 8, ["imageUrl", "data-cy"])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_ColorBar, { class: "w-8/12 m-auto" })
                ])
              ]))
        ]),
        _: 1
      })
    ])
  ]))
}