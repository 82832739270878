export default {
  "navigationBar": {
    "reportResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Results"])},
    "reportSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Summary"])},
    "axialImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Axial Images"])},
    "coronalImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coronal Images"])},
    "ventilation4DVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation 4D Video"])},
    "contact4DMedical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact 4D Medical"])}
  },
  "reportPreview": {
    "readReportViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Read Report Guide"])},
    "ventilationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation Report - Executive Summary"])},
    "ventilationReportHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization"])},
    "ventilationReportHeaderSlices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization Slices"])},
    "readReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Read The Ventilation Report"])},
    "readReportEWD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Read The CT LVAS Ventilation Report"])},
    "reportTextEWD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your CT LVAS Report has not been completed, please contact 4DMedical support."])},
    "reportTextDAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your XV LVAS Report has not been completed, please contact 4DMedical support."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Report Generated"])}
  },
  "positions": {
    "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper"])},
    "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
    "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower"])}
  },
  "ventilationAnimation": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization - Ventilation Video"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4D Dynamic View"])}
  },
  "footer": {
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright©4dmedical.com"])},
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All right reserved"])}
  },
  "mainHeader": {
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCAN ID"])},
    "dateAcquired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE RECEIVED"])},
    "dateProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE PREPARED"])}
  },
  "colorbar": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less"])},
    "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Ventilation"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histogram Gradient"])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact 4DMedical"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4DMedical"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Contact 4DMedical Page!"])},
    "usOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los Angeles Office"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Version"])},
    "releaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device Release Date"])},
    "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
    "jobID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Id"])},
    "issueNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
    "disclaimBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Report provides information to support physicians with their assessment of patients with lung diseases. The Report does not, in itself, provide a diagnosis of lung health. 4DMedical assumes no responsibility for the improper use of, or self-diagnosis using, the Report."])},
    "disclaimBodyRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please see the Instructions for Use for a fuller explanation of all concepts in this Report, including the complete Glossary and References."])}
  },
  "sliceDisplay": {
    "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slice Zoom"])}
  },
  "axialSliceDisplay": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization - Axial Slices"])},
    "planeHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Axial (Transverse) Plane - Every ", _interpolate(_named("spacing")), " mm"])}
  },
  "coronalSliceDisplay": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization - Coronal Slices"])},
    "planeHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Coronal (Frontal) Plane - Every ", _interpolate(_named("spacing")), " mm"])}
  },
  "ventVideo": {
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coronal (Middle) Plane at different inspiratory phase points"])},
    "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select View"])},
    "selectSlice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Slice"])}
  },
  "tidalVolume": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidal Volume"])}
  },
  "videoFrames": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Frames: Regional Ventilation Visualizations (through the breath phase)"])}
  },
  "lungVolume": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung Volumes"])},
    "headerMD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lung Volumes"])}
  },
  "reportSummaryPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation Report - Report Summary"])},
    "ventDynmView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization"])},
    "ventDist": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation Distribution Graph/Histogram"])},
      "Descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The frequency distribution of regional specific ventilation measured across the entire lung at peak inspiration."])}
    },
    "ventHeterog": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation Heterogeneity"])},
      "vdp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDP"])},
      "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Scale"])},
      "large": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Large Scale"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])}
    }
  },
  "errors": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found. Please contact 4DMedical."])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorised. Please contact 4DMedical."])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Server Error. Please contact 4DMedical."])},
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Request. Please contact 4DMedical."])},
    "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden. Please contact 4DMedical."])},
    "502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Gateway. Please contact 4DMedical."])}
  }
}