
import { defineComponent, computed } from 'vue';
import { useScanStore } from '@/stores/scan';

export default defineComponent({
  setup() {
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);

    return { getScan };
  },
});
