import { useNotification } from '@/composables/notification';
import { SliceImageTypes } from '@/constants/SliceImageTypes';
import { useScanStore } from '@/stores/scan';
import { useSliceStore } from '@/stores/slice';
import { AxiosError } from 'axios';

export const handleEndpointSuccess = <T>(data: T) => {
  return { success: true, errorMsg: '', data };
};

export const handleEndpointErrors = (e: unknown, onlyStatus = true) => {
  const err = e as AxiosError;

  if (err?.response) {
    const errorResponse = err.response;
    let errorMsg;

    if (onlyStatus) {
      errorMsg = errorResponse.status;
    } else {
      errorMsg = '404';
    }

    return { success: false, errorMsg, data: null };
  } else if (e instanceof Error) {
    return { success: false, errorMsg: e.message, data: null };
  } else {
    return { success: false, errorMsg: err as unknown as string, data: null };
  }
};

export const getImageBlob = async (type: any, mainUrl: any = '') => {
  const sliceStore = useSliceStore();
  const scanStore = useScanStore();
  const notification = useNotification();
  const getScan = scanStore.getScan;

  const imageRequestPromise = async (url: any) => {
    const imageRequest = await sliceStore.renderImages(getScan!.resultId, url);
    if (!imageRequest.success) {
      notification.showError(imageRequest.errorMsg + '');
    }
    return imageRequest.data!;
  };

  sliceStore.$patch((state) => {
    state.slices
      .find((slice) => slice.plane === type)
      ?.images.map(async (image: { files: any[] }) => {
        const url = image.files.find((f: { type: SliceImageTypes }) => f.type === SliceImageTypes.STATIC)?.url;
        if (mainUrl) {
          if (url.endsWith('.png') && url == mainUrl) {
            const imageRequest = await imageRequestPromise(url);
            image.files.find((f: { type: SliceImageTypes }) => f.type === SliceImageTypes.STATIC).url = imageRequest;
          }
        } else if (url.endsWith('.png')) {
          const imageRequest = await imageRequestPromise(url);
          image.files.find((f: { type: SliceImageTypes }) => f.type === SliceImageTypes.STATIC).url = imageRequest;
        }
      });
  });
};

export const getAllSlices = async (scanId: any) => {
  const sliceStore = useSliceStore();

  // for loop implementation
  for (const [indexType, sliceType] of sliceStore.slices.entries()) {
    for (const [indexImage, image] of sliceType.images.entries()) {
      for (const [indexPosition, position] of image.files.entries()) {
        if (position.url.endsWith('a.png')) {
          const url = await sliceStore.renderImages(scanId, position.url);
          sliceStore.$patch((state) => {
            state.slices[indexType].images[indexImage].files[indexPosition].url = url.data!;
          });
        }
      }
    }
  }
};
