import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid gap-4 gird-cols-3" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "col-span-3" }
const _hoisted_4 = { class: "grid gap-2 3xl:grid-cols-9 xl:grid-cols-10" }
const _hoisted_5 = { class: "a4:col-span-5 xl:col-span-6 col-start-1 3xl:col-span-5 4xl:col-span-5 max-[596px]:ml-24 max-[596px]:mr-24" }
const _hoisted_6 = { class: "col-span-3 a4:col-span-5 xl:col-span-4 3xl:col-span-4 4xl:col-span-4" }
const _hoisted_7 = { class: "grid xl:grid-cols-2 xl:px-1 xl:space-y-1 a4:grid-rows-1" }
const _hoisted_8 = { class: "grid grid-cols-5 gap-3 xl:col-span-3 xl:col-start-1" }
const _hoisted_9 = { class: "a4:bg-blue-100 a4:col-span-3 xl:col-span-6" }
const _hoisted_10 = { class: "xl:col-start-1 xl:col-span-6 a4:bg-red-100 a4:col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_Main4DView = _resolveComponent("Main4DView")!
  const _component_VentilationDistribution = _resolveComponent("VentilationDistribution")!
  const _component_ScanMetrics = _resolveComponent("ScanMetrics")!
  const _component_VentDistributionPercent = _resolveComponent("VentDistributionPercent")!
  const _component_VentHeterog = _resolveComponent("VentHeterog")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.disclaimer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "marked-font inline max-[596px]:text-[.65rem]",
            innerHTML: _ctx.marked.parse(_ctx.disclaimer)
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseHeader, {
          title: _ctx.$t('reportSummaryPage.header')
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", null, [
        (_ctx.getScan)
          ? (_openBlock(), _createBlock(_component_BaseCard, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Main4DView, {
                      showInformationModal: _ctx.showInformationModal,
                      showTitle: !_ctx.previewReport
                    }, null, 8, ["showInformationModal", "showTitle"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_VentilationDistribution, {
                            VDP: _ctx.getScan.histogram.vdp,
                            "data-cy": "ventilation-distribution",
                            showInformationModal: _ctx.showInformationModal
                          }, null, 8, ["VDP", "showInformationModal"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.getScan?.product === 'DAW' ? 'flex flex-row' : '')
                            }, [
                              _createVNode(_component_ScanMetrics, {
                                showInformationModal: _ctx.showInformationModal,
                                class: _normalizeClass(["max-[596px]:border-none max-[596px]:h-1/5 xl:block", _ctx.getScan?.product === 'DAW' ? 'basis-1/2 xl:mr-2' : ''])
                              }, null, 8, ["showInformationModal", "class"]),
                              _createVNode(_component_VentDistributionPercent, {
                                showInformationModal: _ctx.showInformationModal,
                                imageGuide: _ctx.getReportGuideImages.histogramExample,
                                class: _normalizeClass(_ctx.getScan?.product === 'DAW' ? 'basis-1/2' : 'max-[596px]:text-heading-5 mt-[1vh]'),
                                showModal: _ctx.previewReport ? false : true
                              }, null, 8, ["showInformationModal", "imageGuide", "class", "showModal"])
                            ], 2),
                            _createVNode(_component_VentHeterog, {
                              showInformationModal: _ctx.showInformationModal,
                              class: _normalizeClass(["max-[596px]:border-none max-[596px]:h-1/5 mt-[1vh]", _ctx.getScan?.product === 'DAW' ? '4xl:h-60' : ''])
                            }, null, 8, ["showInformationModal", "class"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}