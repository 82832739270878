
import { defineComponent, ref, computed, onMounted } from 'vue';
import Vent4DZoomImg from '../components/reportSummary/Vent4DZoomImg.vue';
import ColorBar from '@/components/ColorbarComponent.vue';
import { Information } from '@/constants/Information';

import { Views } from '@/constants/Views';
import { CoronalSlices, AxialSlices } from '@/constants/Slices';

import { useSliceStore } from '@/stores/slice';
import { File } from '@/types/Slice';
import { SliceImageTypes } from '@/constants/SliceImageTypes';
import { useScanStore } from '@/stores/scan';
import { useNotification } from '@/composables/notification';
import { POSITION } from 'vue-toastification';
import LoadingBarImg from '@/assets/images/loading-2.jpg';
import { getAllSlices } from '@/utility/helpers';

export default defineComponent({
  components: {
    Vent4DZoomImg,
    ColorBar,
  },
  props: {
    previewReport: {
      type: Boolean,
      value: false,
    },
    showInformationModal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    //constants
    const viewsArray = Object.values(Views);
    const coronalSlicesArray = Object.values(CoronalSlices);
    let axialSlicesArray: string[] = Object.values(AxialSlices);
    viewsArray.slice(viewsArray.length / 2);
    coronalSlicesArray.slice(coronalSlicesArray.length / 2);
    axialSlicesArray.slice(axialSlicesArray.length / 2);
    axialSlicesArray = axialSlicesArray.map((s) => (s === AxialSlices.CENTRE ? CoronalSlices.MIDDLE : s));

    //slices functionality
    const sliceStore = useSliceStore();

    const reArrangeAnimatedFiles = (files: File[]) => {
      const animatedFiles = files.filter((f) => f.type === SliceImageTypes.ANIMATED);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      animatedFiles.sort((a, b) => a.animationIndex! - b.animationIndex!);
      return animatedFiles;
    };

    const coronalAnimatedSlices = computed(() => {
      // we want to only get Animated Slices
      const slices = sliceStore.filterSliceImages(Views.CORONAL, true);

      let newSlices = slices.map((slice) => {
        return {
          files: reArrangeAnimatedFiles(slice.files),
          position: slice.position,
          order: slice.order,
          pixelPosition: slice.pixelPosition,
          named: slice.named,
          distance: slice.distance,
        };
      });
      return newSlices;
    });
    const axialAnimatedSlices = computed(() => {
      // we want to only get Animated Slices

      let slices = sliceStore.filterSliceImages(Views.AXIAL, true);

      let newSlices = slices.map((slice) => {
        return {
          files: reArrangeAnimatedFiles(slice.files),
          position: slice.position,
          order: slice.order,
          pixelPosition: slice.pixelPosition,
          named: slice.named,
          distance: slice.distance,
        };
      });
      return newSlices;
    });

    const phasePoint = ref(0);
    const minPhasePoint = 0;
    const maxPhasePoint = coronalAnimatedSlices.value[0]?.files?.length - 1 || 7;

    const leftViewValue = ref<Views>(Views.CORONAL);
    const leftSliceValue = ref<CoronalSlices | AxialSlices>(CoronalSlices.MIDDLE);

    const rightViewValue = ref<Views>(Views.AXIAL);
    const rightSliceValue = ref<CoronalSlices | AxialSlices>(CoronalSlices.MIDDLE); //setting to coronal middle since replacing axial centre with axial middle

    const isCoronalLeft = computed(() => {
      if (leftViewValue.value == Views.CORONAL) {
        return true;
      }

      return false;
    });

    const isCoronalRight = computed(() => {
      if (rightViewValue.value == Views.CORONAL) {
        return true;
      }
      return false;
    });

    const changeSliceValue = (isCoronal: boolean, value: CoronalSlices | AxialSlices) => {
      //no axial centre since it should get covered by coronal middle
      switch (value) {
        case CoronalSlices.BACK:
          return isCoronal ? value : AxialSlices.LOWER;
        case CoronalSlices.FRONT:
          return isCoronal ? value : AxialSlices.UPPER;
        case CoronalSlices.MIDDLE:
          return value;
        case AxialSlices.LOWER:
          return isCoronal ? CoronalSlices.BACK : value;
        default:
          return isCoronal ? CoronalSlices.FRONT : value;
      }
    };

    const onLeftViewChange = () => {
      leftSliceValue.value = changeSliceValue(isCoronalLeft.value, leftSliceValue.value);
    };

    const onRightViewChange = () => {
      rightSliceValue.value = changeSliceValue(isCoronalRight.value, rightSliceValue.value);
    };

    const leftViewImage = computed(() => {
      if (isCoronalLeft.value) {
        return coronalAnimatedSlices.value.find((s) => s.position === leftSliceValue.value)?.files[phasePoint.value]
          .url;
      } else {
        const val = leftSliceValue.value === CoronalSlices.MIDDLE ? AxialSlices.CENTRE : leftSliceValue.value;
        return axialAnimatedSlices.value.find((s) => s.position === val)?.files[phasePoint.value].url;
      }
    });

    const scanStore = useScanStore();
    const notification = useNotification();

    const getScan = computed(() => scanStore.getScan);

    const rightViewImage = computed(() => {
      if (isCoronalRight.value) {
        return coronalAnimatedSlices.value.find((s) => s.position === rightSliceValue.value)?.files[phasePoint.value]
          .url;
      } else {
        const val = rightSliceValue.value === CoronalSlices.MIDDLE ? AxialSlices.CENTRE : rightSliceValue.value;
        return axialAnimatedSlices.value.find((s) => s.position === val)?.files[phasePoint.value].url;
      }
    });

    //range functions
    const isPlaying = ref(false);
    let isIncreasing = true;
    let interval = 0;

    const onPlayerClick = () => {
      isPlaying.value = !isPlaying.value;

      if (isPlaying.value) {
        interval = setInterval(() => {
          if (isIncreasing && phasePoint.value < maxPhasePoint) {
            phasePoint.value++;
          } else if (isIncreasing && phasePoint.value === maxPhasePoint) {
            isIncreasing = false;
            phasePoint.value--;
          } else if (!isIncreasing && phasePoint.value > minPhasePoint) {
            phasePoint.value--;
          } else {
            isIncreasing = true;
            phasePoint.value++;
          }
        }, 1000);
      } else {
        clearInterval(interval);
      }
    };

    const onLeftPlayerClick = () => {
      isPlaying.value = false;
      clearInterval(interval);

      if (phasePoint.value > minPhasePoint) {
        phasePoint.value--;
      }
    };

    const onRightPlayerClick = () => {
      isPlaying.value = false;
      clearInterval(interval);

      if (phasePoint.value < maxPhasePoint) {
        phasePoint.value++;
      }
    };
    const coronalVideoUrl = ref<(string | null)[]>([]);

    onMounted(async () => {
      if (props.previewReport) {
        coronalVideoUrl.value = await Promise.all(
          coronalAnimatedSlices.value[1].files.map(async (image) => {
            if (image.url.endsWith('.png')) {
              const respTwo = await sliceStore.renderImages(getScan.value?.resultId, image.url);
              if (!respTwo.success) {
                return null;
              }

              return respTwo.data;
            } else {
              return image.url;
            }
          }),
        );
      } else {
        getAllSlices(getScan.value?.resultId);
      }
    });

    return {
      viewsArray,
      coronalSlicesArray,
      axialSlicesArray,

      onLeftViewChange,
      onRightViewChange,
      leftViewValue,
      rightViewValue,
      leftSliceValue,
      rightSliceValue,
      leftViewImage,
      rightViewImage,
      isCoronalLeft,
      isCoronalRight,
      Information,
      coronalVideoUrl,

      isPlaying,
      onPlayerClick,
      onLeftPlayerClick,
      onRightPlayerClick,
      phasePoint,
      minPhasePoint,
      maxPhasePoint,

      coronalAnimatedSlices,
      sliceStore,
      loadingBarImg: LoadingBarImg,
    };
  },
});
