import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportSummary = _resolveComponent("ReportSummary")!
  const _component_AxialImages = _resolveComponent("AxialImages")!
  const _component_CoronalImages = _resolveComponent("CoronalImages")!
  const _component_VentilationVideo = _resolveComponent("VentilationVideo")!
  const _component_HowToReadReport = _resolveComponent("HowToReadReport")!
  const _component_EmptyReport = _resolveComponent("EmptyReport")!
  const _component_Contact4DMedical = _resolveComponent("Contact4DMedical")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getScan.result.status === `OK`)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ReportSummary, {
            "data-cy": "report-summary",
            showInformationModal: false,
            previewReport: true
          }),
          _createVNode(_component_AxialImages, {
            "data-cy": "axial-images",
            class: "break-before-page",
            showInformationModal: false,
            showSlicedZoom: false
          }),
          _createVNode(_component_CoronalImages, {
            "data-cy": "coronal-images",
            class: "break-before-page",
            showInformationModal: false,
            showSlicedZoom: false
          }),
          (_ctx.getScan.product === _ctx.ReportTypes.DAW)
            ? (_openBlock(), _createBlock(_component_VentilationVideo, {
                key: 0,
                class: "break-before-page max-[596px]:height-2/5",
                "data-cy": "ventilation-video-report",
                previewReport: true,
                showInformationModal: false
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_HowToReadReport, {
            "data-cy": "how-to-read-report",
            showInformationModal: false,
            reportType: _ctx.getScan.product,
            class: "break-before-page"
          }, null, 8, ["reportType"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_EmptyReport)
        ])),
    _createVNode(_component_Contact4DMedical, {
      "data-cy": "contact4d-medical",
      class: "break-before-page",
      previewReport: true
    })
  ]))
}