
import ColorbarImg from '@/assets/images/color_20px.png';

export default {
  data() {
    return {
      colorbarImg: ColorbarImg,
    };
  },
};
