import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ReportSummary from '../views/ReportSummary.vue';
import AxialImages from '../views/AxialImages.vue';
import CoronalImages from '../views/CoronalImages.vue';
import VentilationVideo from '../views/VentilationVideo.vue';
import PreviewReport from '../views/PreviewReport.vue';
import Contact4DMedical from '../views/Contact4DMedical.vue';
import ErrorPage from '../views/ErrorPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/error/:type',
    name: 'error-page',
    component: ErrorPage,
    meta: {
      layout: 'NoLayout',
    },
  },
  {
    path: '/result-summary',
    name: 'Report Summary',
    component: ReportSummary,
  },
  {
    path: '/axial-images',
    name: 'Axial Images',
    component: AxialImages,
  },
  {
    path: '/coronal-images',
    name: 'Coronal Images',
    component: CoronalImages,
  },
  {
    path: '/ventilation-video',
    name: 'Ventilation VIdeo',
    component: VentilationVideo,
  },
  {
    path: '/access/:resultId/:secret/preview-report',
    component: PreviewReport,
    meta: {
      layout: 'NoLayout',
    },
  },
  {
    path: '/contact4d-medical',
    name: 'Contact 4D Medical',
    component: Contact4DMedical,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
