<template>
  <BaseSubCard>
    <div>
      <BaseSubHeader
        :title="vdgLabel"
        :showInformationModal="showInformationModal"
        :informationModalText="Information.VENTILATION_GRAPH_HISTOGRAM"
        :imageGuide="getReportGuideImages.histogramExample"
        :markdown="true"
      />
      <p class="text-body-2 4xl:text-heading-5">{{ histogramDescLabel }}</p>
      <canvas ref="ventDistChart"></canvas>
      <ColorBar class="ml-10 a4:w-auto" />
    </div>
  </BaseSubCard>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

import ColorBar from '../ColorbarComponent.vue';

import { Information } from '@/constants/Information';
import { useScanStore } from '@/stores/scan';
import { useInformationStore } from '@/stores/information';
import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  name: 'VentDistChart',
  components: { ColorBar },
  props: {
    VDP: {
      type: Number,
      required: true,
    },

    showInformationModal: {
      type: Boolean,
      required: false,
    },
  },

  setup(props) {
    const scan = useScanStore();
    const information = useInformationStore();
    const getReportGuideImages = computed(() => information.getReportGuideImages);
    const getScan = computed(() => scan.getScan);
    const ventDistChart = ref(null);

    const labelsStore = useLabelsStore();
    const vdgLabel = computed(() => labelsStore.getLabels?.vdg);
    const histogramDescLabel = computed(() => labelsStore.getLabels?.histogramDesc);

    onMounted(() => {
      if (!getScan.value || !ventDistChart.value) {
        return;
      }

      const xData = getScan.value.histogram.data.map(({ x }) => x.toFixed(2));
      const yData = getScan.value.histogram.data.map(({ y }) => y * 100);
      const legend = 'VDP';

      Chart.register(annotationPlugin);
      const ctx = ventDistChart.value.getContext('2d');

      if (props.showInformationModal) {
        getScan.value.product === 'DAW' ? (ctx.canvas.height = 115) : (ctx.canvas.height = 75);
      }

      if (!ctx) {
        return;
      }

      const gradient = {
        grey: ctx.createLinearGradient(0, 0, 0, 600),
        salmon: ctx.createLinearGradient(0, 0, 0, 600),
      };
      gradient.grey.addColorStop(0, 'rgba(32, 32, 32, .5)');
      gradient.grey.addColorStop(1, 'rgba(32, 32, 32, 1)');
      gradient.salmon.addColorStop(0, 'rgba(253, 124, 110, .25)');
      gradient.salmon.addColorStop(1, 'rgba(253, 124, 110, 1)');

      const chartData = {
        labels: xData,
        datasets: [
          {
            label: legend,
            data: yData,
            backgroundColor: gradient.grey,
            borderColor: 'rgb(32, 32, 32)',
          },
        ],
      };

      const chartAreaBorder = {
        type: 'box',
        xScaleID: 'xAxes',
        yScaleID: 'yAxes',
        backgroundColor: 'transparent',
        borderColor: 'rgb(105,105,105)',
        borderWidth: 2,
      };

      const annotationBox = {
        type: 'box',
        xScaleID: 'xAxes',
        yScaleID: 'yAxes',
        xMax: '0.60',
        backgroundColor: gradient.salmon,
        borderColor: 'transparent',
        borderWidth: 1,
        drawTime: 'beforeDatasetsDraw',
      };

      const annotationText = {
        type: 'label',
        xScaleID: 'xAxes',
        yScaleID: 'yAxes',
        xMin: xData[0],
        xMax: (xData.length * props.VDP) / 100,
        yMin: Math.max(...yData) - 1,
        yMax: Math.max(...yData) - 0.5,
        borderColor: 'transparent',
        label: {
          enabled: true,
          content: ['VDP ' + props.VDP + '%'],
          backgroundColor: 'transparent',
        },
      };

      const histogramChart = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          plugins: {
            autocolors: false,
            legend: { display: false },
            annotation: {
              annotations: [
                chartAreaBorder,
                annotationBox,
                annotationText,
                {
                  type: 'label',
                  xMin: xData[0],
                  xMax: (xData.length * props.VDP) / 100,
                  yMin: Math.max(...yData) - 1,
                  yMax: Math.max(...yData) - 0.5,

                  backgroundColor: 'transparent',
                  font: {
                    size: 10,
                  },
                },
              ],
            },
          },
          scales: {
            xAxes: {
              display: false,
            },
            x: {
              display: false,
            },
            y: {
              display: false,
            },
            yAxes: {
              title: {
                display: true,
                text: 'Percentage',
              },
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          },
          events: [],
        },
      });
    });

    return { ventDistChart, Information, vdgLabel, histogramDescLabel, getReportGuideImages };
  },
});
</script>
